import type {
	ILoginState,
	IUserState,
	ICompanyDetails,
	IPayment,
	IInvite,
	ILoginUser,
} from '.';

import { atom } from 'recoil';

import { ROLE } from 'constant';

const getCookie = (key: string) => {
	const name = key + '=';
	const decodedCookie = decodeURIComponent(document.cookie);
	const decodeCookie = decodedCookie.split(';');
	for (let i = 0; i < decodeCookie.length; i++) {
		let decodeKey = decodeCookie[i];
		while (decodeKey?.charAt(0) === ' ') {
			decodeKey = decodeKey.substring(1);
		}
		if (decodeKey?.indexOf(name) === 0) {
			return decodeKey.substring(name.length, decodeKey.length);
		}
	}
	return '';
};

const getUser = () => {
	try {
		const localStorageData = JSON.parse((getCookie('user') as any) ?? null);
		const {
			token,
			role,
			loggedIn,
			developer,
			plan,
			business,
			status,
			linkedInUrl,
		} = localStorageData ?? {};
		const { OWNER } = ROLE;
		const checkoutStorageData = JSON.parse(
			localStorage.getItem('checkout') as any
		);
		const { id, paymentStatus, paymentIntent } = checkoutStorageData ?? {};
		return {
			user: {
				accessToken: token ?? '',
				loggedIn: loggedIn ?? false,
				role: role ?? OWNER,
				developer: developer ?? ',',
				plan: plan ?? '',
				webToken: '',
				business: business,
				status,
				linkedInUrl: linkedInUrl ?? '',
			},
			payment: {
				id: id ?? '',
				paymentStatus: paymentStatus ?? '',
				paymentIntent: paymentIntent ?? '',
			},
		};
	} catch (error: any) {
		// DOMException: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.
		if (/Access is denied/gi.test(error.message ?? '')) {
			// TODO: @awadhesh add logic to get user data (API call)
		}
		return {
			user: {
				accessToken: '',
				loggedIn: false,
				role: ROLE.OWNER,
				developer: false,
				business: '',
				plan: '',
				webToken: '',
				status: '',
				linkedInUrl: '',
			},
			payment: {
				id: '',
				paymentStatus: '',
				paymentIntent: '',
			},
		};
	}
};

export const loginState = atom<ILoginState>({
	key: 'login',
	default: getUser().user,
});

export const userState = atom<IUserState>({
	key: 'user-state',
	default: {
		userId: '',
	},
});

export const companyDetailsState = atom<ICompanyDetails>({
	key: 'company-state',
	default: {
		name: '',
		address: {
			address1: '',
			address2: '',
			zip: '',
			city: '',
			state: '',
		},
		logo: '',
		linkedInUrl: '',
		purposeOfUsing: '',
		billingAddress: {
			address1: '',
			address2: '',
			zip: '',
			city: '',
			state: '',
		},
	},
});

export const paymentState = atom<IPayment>({
	key: 'payment-state',
	default: getUser().payment,
});

export const InviteDetailsState = atom<IInvite>({
	key: 'invite-user-role-state',
	default: {
		firstName: '',
		lastName: '',
		email: '',
		role: 'ADMIN',
		funds: [],
		developer: false,
		isAgentPermitted: false,
	},
});

export interface IPhoneNumber {
	phone: string;
	countryCode: string | number;
}
const user = { phone: '', token: '', countryCode: '', isLoggedIn: false };

export const SendOTPState = atom<boolean>({
	key: 'send-otp',
	default: false,
});

export const LoginUserState = atom<ILoginUser>({
	key: 'login-user-data',
	default: {
		phone: user?.phone ?? '',
		countryCode: user?.countryCode ?? '',
		token: user?.token ?? '',
		isLoggedIn: user?.isLoggedIn ?? false,
	},
});

// export const LoginPhoneNumberState = atom<IPhoneNumber>({
//   key: "login-phone-state",
//   default: {
//     phone: "",
//     countryCode: "+1",
//   },
// });

export const FacialDataState = atom<any>({
	key: 'facial-data',
	default: {},
});

export const InviteFirstNameInputState = atom({
	key: 'invite-first-name-input-key',
	default: false,
});
export const InviteLastNameInputState = atom({
	key: 'invite-last-name-input-key',
	default: false,
});
export const InviteEmailInputState = atom({
	key: 'invite-email-input-key',
	default: false,
});

export const InviteFundState = atom({
	key: 'invite-funds-input-key',
	default: false,
});

export const UserRoleState = atom({
	key: 'user-role-data-state',
	default: null,
});

export const InviteModal = atom<boolean>({
	key: 'invite-modal',
	default: false,
});
export const EditModal = atom<boolean>({
	key: 'edit-modal',
	default: false,
});

export const userDataState = atom<any>({
	key: 'user-data',
	default: {},
});

export const IsCompanyVerifiedState = atom<boolean>({
	key: 'is-company-veryfied-state',
	default: true,
});

export const OtpEmailState = atom<string>({
	key: 'otp-email-state',
	default: '',
});

export const ForgotPageState = atom<string>({
	key: 'forgot-page-state',
	default: 'forgot',
});

export const NewGmailState = atom<string>({
	key: 'new-gmail-state',
	default: '',
});

export const senderDetailsState = atom<any>({
	key: 'sender-details-state',
	default: {},
});

export const recipitentRejectedState = atom<boolean>({
	key: 'recipitent-reject-state',
	default: false,
});

export const recipitentDetailsState = atom<any>({
	key: 'recipitent-Details-state',
	default: {},
});
export const recipitentDetailsResponseState = atom<boolean>({
	key: 'recipitent-Details-Response-state',
	default: false,
});

export const userLocationState = atom<any>({
	key: 'user-location-state',
	default: {},
});
export const webAuthStatus = atom<any>({
	key: 'web-auth-status',
	default: false,
});

export const recipientIdState = atom<any>({
	key: 'recipient-is-state',
	default: '',
});
export const SendImageState = atom<any>({
	key: 'send-image-state',
	default: null,
});
export const BlinkcounterState = atom<any>({
	key: 'blink-counter-state',
	default: 0,
});

export const SenderImageState = atom<any>({
	key: 'sender-image-state',
	default: '',
});

export const RecipientImageState = atom<any>({
	key: 'recipient-image-state',
	default: '',
});

export const SenderStatusLoader = atom<any>({
	key: 'Sender-Status-Loader',
	default: true,
});

export const RecipientImagesArrayState  = atom<any>({
	key: 'recipient-image-array',
	default: [],
});