/* eslint-disable no-console */
/* eslint-disable no-mixed-spaces-and-tabs */
import { FC, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, Loader } from '@storybook';
import {
	FacialPropsState,
	SignInActiveStepState,
	recipitentDetailsState,
	recipitentRejectedState,
	userLocationState,
	recipientIdState,
	jackActiveStepsState,
} from 'states';
import style from './choose-method.module.sass';
import { useNetwork } from 'hooks';
import { API_URL } from 'constant';
import { WaitingApproval } from 'views/face-auth/components';
import { ShowWatingApprovalState } from 'views/face-auth/stores';
import {
	REACT_APP_COUNTRY_CODE as countryCode,
	REACT_APP_NAME as name,
	REACT_APP_MOBILE_NUMBER as phNumber,
	REACT_APP_API_HOST as API_HOST,
} from 'envs';
import { jackTranstionKeys } from 'views/verification-auth/states/jackstate';

export interface IPipelineMatch {
	match: boolean;
	enrollmentId: string;
	data: any;
}

export interface IMatch {
	_label: string;
	_distance: number;
}

interface IChooseMethod {
	fromBank?: boolean;
	fromJack?: boolean;
}

export const ChooseMethod: FC<IChooseMethod> = ({ fromBank, fromJack }) => {
	//globle states
	const setSignInActiveStep = useSetRecoilState(SignInActiveStepState);
	const setRecipientUserId = useSetRecoilState(recipientIdState);
	const facialPropsState = useRecoilValue(FacialPropsState);
	const handleFaceRecognition = useCallback(() => {
		setSignInActiveStep('face-recognize');
	}, []);
	const [webAuthLoader, setWebAuthLoader] = useState(false);
	const setJackActiveSteps = useSetRecoilState(jackActiveStepsState);
	const setShowWatingApprovalState = useSetRecoilState(ShowWatingApprovalState);
	const setJackTranstionKeys = useSetRecoilState(jackTranstionKeys);

	const address = useRecoilValue(userLocationState);

	const setRecipitetentDetails = useSetRecoilState(recipitentDetailsState);
	// const setRecipitentRejected = useSetRecoilState(recipitentRejectedState);
	const [recipientRejected, setRecipitentRejected] = useRecoilState(
		recipitentRejectedState
	);
	// loacl state
	const [recipientData, setRecipientData] = useState<any>({});
	const [isWating, setIsWaiting] = useState<boolean>(recipientRejected);
	// const [title, setTitle] = useState<string>('Choose Method');

	const { onBack } = facialPropsState ?? {};

	// hooks
	// const { getRegistrations, registerNewCredentials, authenticate } =
	// 	useWebAuthentication();
	const { post, get } = useNetwork();

	const handleGoBack = useCallback(() => {
		if (!onBack) {
			setSignInActiveStep('phone');
			return;
		}
		onBack();
	}, []);

	// useEffect(() => {
	//  if (fromBank) {
	//      setTitle('Click to proceed');
	//  }
	// }, []);

	const extractValues = (fullName: string) => {
		const regex = /(.*) <(.*)>/;
		const matches = fullName.match(regex);

		if (matches && matches.length === 3) {
			const name = matches[1]?.trim();
			const value = matches[2];

			return { name, value };
		}

		return null;
	};

	const handleMatchUser = useCallback((id: string) => {
		const checkUser = setInterval(async () => {
			get(`${API_URL.REQUEST_RECIPIENT + '/' + id}`)
				.then((resp) => {
					setRecipientData(resp.data);
					if (resp.data?.status === 'complete') {
						clearInterval(checkUser);
						setRecipitentRejected(true);
						setRecipitetentDetails(resp.data);
					}
					if (resp.data?.status === 'rejected') {
						setRecipitentRejected(true);
						clearInterval(checkUser);
						setRecipitetentDetails(resp.data);
					}
				})
				// eslint-disable-next-line no-console
				.catch((error) => console.log(error));
		}, 2000);
	}, []);

	const handleSuccess = useCallback(() => {
		console.log('facial props ', facialPropsState);
		if (fromJack) {
			setWebAuthLoader(true);
			// const data = document.getElementById(
			// 	'txtToValue'
			// ) as HTMLInputElement | null;
			// const value = data?.value;
			const fbAmount = (
				document
					?.querySelector(
						'body > banno-web > bannoweb-layout > bannoweb-zelle-send'
					)
					?.shadowRoot?.querySelector?.('#frmEnterAmount')
					?.shadowRoot?.querySelector?.('#amount') as
					| HTMLInputElement
					| undefined
			)?.value?.toString();
			const boaAmount = (
				document.getElementById('txtAmount') as HTMLInputElement | undefined
			)?.value?.toString();
			let personName = name;
			let phoneNumber = phNumber;
			let email: any = '';
			try {
				personName =
					document
						?.querySelector(
							'body > banno-web > bannoweb-layout > bannoweb-zelle-send'
						)
						?.shadowRoot?.querySelector('#frmEnterAmount')
						?.shadowRoot?.querySelector('form > h2')
						?.textContent?.replace('Send to ', '') ?? name;
				if (/John/gi.test(personName)) {
					phoneNumber = '+16122401819';
					email = 'johnnywhitaker@gmail.com';
				} else if (/Abhishek/gi.test(personName)) {
					phoneNumber = '+918939155935';
					email = 'Abhishek.Mahra@satschel.com';
				} else if (/Alina/gi.test(personName)) {
					if (API_HOST.includes('pp')) {
						phoneNumber = '+918586808804';
						email = 'vaishali.jain@satschel.com';
					} else {
						phoneNumber = '+14157416782';
						email = 'alina.trombley@satschel.com';
					}
				} else if (/Jim/gi.test(personName)) {
					phoneNumber = '+16127207022';
					email = 'jim.delaney@satschel.com';
				} else {
					if (fromJack) {
						phoneNumber = '+918283847779';
						email = 'arun2611998@gmail.com';
					} else {
						phoneNumber = phNumber;
						email = 'Alina.trombley@satschel.com';
					}
				}
			} catch (error) {
				console.log('error', error);
			}

			const amount = fbAmount ?? boaAmount ?? '1';
			const payload = {
				sender: {
					phone:
						facialPropsState?.countryCode + facialPropsState?.mobileNumber ??
						'+918126880650',
					notification: {
						email: true,
						phone: true,
					},
					liveness: {
						gestures: [
							{
								id: 7,
								name: 'Smile',
								type: 'mouthSmileRight',
								video:
									'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media/Smile.mp4',
								image: 'NA',
								description: 'NA',
								threshold: 0.6,
								frequency: 1.0,
							},
							{
								id: 2,
								name: 'Open Mouth',
								type: 'jawOpen',
								video:
									'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media/Open_mouth.mp4',
								image: 'http://surl.li/nruox',
								description: 'NA',
								threshold: 0.1,
								frequency: 1.0,
							},
						],
					},
					face_match: true,
				},
				recipient: {
					phone: phoneNumber ?? '+918283847779',
					name: personName ?? 'Abhishek Mehra',
					email: email,
					notification: {
						email: true,
						phone: true,
					},
					liveness: {
						gestures: [
							{
								id: 7,
								name: 'Smile',
								type: 'mouthSmileRight',
								video:
									'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media/Smile.mp4',
								image: 'NA',
								description: 'NA',
								threshold: 0.6,
								frequency: 1.0,
							},
							{
								id: 2,
								name: 'Open Mouth',
								type: 'jawOpen',
								video:
									'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media/Open_mouth.mp4',
								image: 'http://surl.li/nruox',
								description: 'NA',
								threshold: 0.1,
								frequency: 1.0,
							},
						],
					},
					face_match: false,
				},
				amount: amount ?? 1,
				callbackUrl: 'https://google.co.in/',
			};
			const apiUrl = 'https://api.stage.satschel.com/v2/rocket/generates/';
			const headers = {
				'Content-Type': 'application/json',
				// Include any additional headers if needed
			};

			fetch(apiUrl, {
				method: 'POST',
				headers: headers,
				body: JSON.stringify(payload),
			})
				.then((response) => {
					// Check if the request was successful (status code 200)
					if (!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}
					return response.json();
				})
				.then((responseData) => {
					// Update the state with the response data
					console.log('POST request successful!', responseData);
					setJackTranstionKeys(responseData);
					setJackActiveSteps('waiting-screen');
				})
				.catch((error) => {
					console.error('Error making POST request:', error.message);
				});

			return;
		}

		// --------------------------------------------------------------------------
		// eslint-disable-next-line no-console
		setIsWaiting(true);
		setWebAuthLoader(false);
		const data = document.getElementById(
			'txtToValue'
		) as HTMLInputElement | null;
		const value = data?.value;
		const fbAmount = (
			document
				?.querySelector(
					'body > banno-web > bannoweb-layout > bannoweb-zelle-send'
				)
				?.shadowRoot?.querySelector?.('#frmEnterAmount')
				?.shadowRoot?.querySelector?.('#amount') as HTMLInputElement | undefined
		)?.value?.toString();
		const boaAmount = (
			document.getElementById('txtAmount') as HTMLInputElement | undefined
		)?.value?.toString();
		let personName = name;
		let phoneNumber = phNumber;
		let email: any = '';
		try {
			personName =
				document
					?.querySelector(
						'body > banno-web > bannoweb-layout > bannoweb-zelle-send'
					)
					?.shadowRoot?.querySelector('#frmEnterAmount')
					?.shadowRoot?.querySelector('form > h2')
					?.textContent?.replace('Send to ', '') ?? name;
			if (/John/gi.test(personName)) {
				phoneNumber = '6122401819';
				email = 'johnnywhitaker@gmail.com';
			} else if (/Abhishek/gi.test(personName)) {
				phoneNumber = '8939155935';
				email = 'Abhishek.Mahra@satschel.com';
			} else if (/Alina/gi.test(personName)) {
				if (API_HOST.includes('pp')) {
					phoneNumber = '8586808804';
					email = 'vaishali.jain@satschel.com';
				} else {
					phoneNumber = '4157416782';
					email = 'alina.trombley@satschel.com';
				}
			} else if (/Jim/gi.test(personName)) {
				phoneNumber = '6127207022';
				email = 'jim.delaney@satschel.com';
			} else {
				phoneNumber = phNumber;
				email = 'Alina.trombley@satschel.com';
			}
		} catch (error) {
			console.log('error', error);
		}

		const amount = fbAmount ?? boaAmount ?? '1';
		const payload = {
			name: personName,
			phone: phoneNumber,
			countryCode: countryCode,
			// name: 'Alina Trombley',
			// phone: '4157416782',
			// countryCode: '+1',
			image: 'abc',
			recipient_image: 'abc', //optional
			senderImage: '',
			amount,
			email: email ?? 'alina.trombley@satschel.com', //static for now
			location: address?.city + ', ' + address?.country,
			senderPhone: facialPropsState.mobileNumber ?? '',
			senderName: 'Austin', //static for now
			// senderName: 'Abhishek Mahra', //static for now
			senderCountryCode: facialPropsState.countryCode, //static for now
		};

		// if (isDev()) {
		//  payload.name = 'Abhishek Mahra';
		//  payload.phone = '8939155935';
		//  payload.countryCode = '+91';
		// }

		if (value) {
			try {
				const fullName = value;
				const extractedData = extractValues(fullName);
				// Uncomment for alina number
				payload.name = extractedData?.name ?? 'Alina Trombley';
				// payload.countryCode = '+1';
				// payload.phone = extractedData?.value ?? '4157416782';
			} catch (err) {
				// eslint-disable-next-line no-console
				console.log(err);
			}
		}
		post(`${API_URL.REQUEST_RECIPIENT}`, payload)
			.then((resp) => {
				if (resp?._id) {
					// setIsCompleted(true);
					handleMatchUser(resp._id);
					setRecipientUserId(resp._id);
				}
			})
			.catch((error) => error);
	}, [facialPropsState]);

	// const handleNextToWebAuth = useCallback(() => {
	// 	setWebAuthLoader(true);
	// 	const isAlreadyExist = getRegistrations().find(
	// 		(item: any) => item.name === mobileNumber ?? ''
	// 	);
	// 	const registerPayload = {
	// 		email: mobileNumber ?? '',
	// 		id: mobileNumber ?? '',
	// 		displayName: mobileNumber ?? '',
	// 	};
	// 	if (!isAlreadyExist) {
	// 		registerNewCredentials(handleSuccess, registerPayload);
	// 	} else {
	// 		authenticate(handleSuccess, () => ({}));
	// 	}
	// }, [authenticate, getRegistrations, mobileNumber, registerNewCredentials]);

	const manageButtonLabel = useMemo((): string | JSX.Element => {
		if (webAuthLoader) {
			return <Loader className="loader-white" dimension={18} />;
		}
		return 'Next';
	}, [webAuthLoader]);

	const renderItem = useMemo(
		() => (
			<div className={style.wrapper}>
				<div
					className={`${style.inner_wrapper} ${
						fromBank ? style.inner_wrapper_fromBank : ''
					}`}
				>
					<div className={style.inner}>
						{!fromBank && (
							<div>
								<div className={style.sub_title}>
									Select the method through which you want sign in
								</div>
							</div>
						)}

						{/* for Now  It is there  */}
						{fromBank ? (
							<div className={style.sub_title}>
								We will be sending notification to the recipient for the
								approval. Click next to proceed.
							</div>
						) : (
							<div className={style.edit}>
								We will send you a confirmation code :{' '}
								{facialPropsState?.countryCode} {facialPropsState?.mobileNumber}{' '}
								<span onClick={() => handleGoBack()} className={style.edit_Btn}>
									Edit
								</span>
							</div>
						)}

						{!fromBank && (
							<div className={style.lists}>
								<div
									className={style.list_item}
									onClick={handleFaceRecognition}
								>
									<div className={style.fillCircle}>
										<div className={style.filled} />
									</div>
									<center>
										<div className={style.left_icon}>
											<i className="ri-body-scan-fill"></i>
										</div>
										<div className={style.method_title}>Facial Recognition</div>
									</center>
								</div>
							</div>
						)}
					</div>
					<div className={style.chooseMethod_btn}>
						<Button
							type="button__filled--secondary button__large button__block"
							label="Back"
							handleClick={handleGoBack}
						/>
						{fromBank && (
							<Button
								type="button__filled--primary button__large button__block"
								label={manageButtonLabel}
								handleClick={handleSuccess}
							/>
						)}
					</div>
				</div>
			</div>
		),
		[handleFaceRecognition, facialPropsState, manageButtonLabel]
	);

	const handleFaceArc = useCallback(() => {
		setShowWatingApprovalState(false);
		setSignInActiveStep('face-recognize');
	}, []);
	return isWating ? (
		<WaitingApproval
			recipientData={recipientData}
			width={500}
			handleNextPay={() => handleFaceArc()}
			setTitle={() => ({})}
		/>
	) : (
		renderItem
	);
};
