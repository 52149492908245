import { atom } from 'recoil';

export const jackTranstionKeys = atom<any>({
	key: 'jack-transctions-keys-state',
	default: {},
});

export const jackRecipientDetails = atom<any>({
	key: 'jack-recipient-details',
	default: {},
});
